.gradient-background {
  background: linear-gradient(271deg,#16171C,#8C509F,  #c02f7f,  #089EBA,  #01DBEC);
  background-size: 180% 180%;
  animation: gradient-animation 18s ease infinite;
}
  
  @keyframes gradient-animation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

.icon {
  max-width: 48px;
  max-height: 48px;
}

.smoothlist {
  min-width: 100%;
  height: 100%;
  display: flex;
  align-items: center; 
  padding: 0; 
  margin-left: auto;
}

.smoothlistChild{
}

#bottom-frame-glow {
  stroke: #16171C;
}